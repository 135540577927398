import React from 'react'
import { Col } from 'react-bootstrap'

const SideBar = ({ children, title, phone, email, hasAbout, hasCare, hasContact }) => (
    <Col className='sidebarBox' xs='12' sm='12' md='4' lg='4'>
        {children}
        {hasAbout ? 
        <Col className='blue'>
            <h3>About Dr. Sambataro</h3>
            <p>Dr. Sambataro is a board certified internal medicine physician. Born and raised in North Jersey, he is extremely familiar with the needs of patients living in this fast paced, tightly knit part of the country.</p>
            <button className='btn btn-secondary center-block'><a href='/about/'>Read More</a></button>
        </Col> : ''}
        {hasCare ? 
        <Col className='lightBlue'>
            <h3>Direct Primary Care</h3>
            <p>Busy people who care about their health and wellness shouldn't have to sacrifice their time and daily commitments just to be taken care of by their doctor.</p>
            <button className='btn btn-secondary center-block'><a href='/direct-care/'>View Our Services</a></button>
        </Col> : ''}
        {hasContact ? 
        <Col className='gray'>
            <h3>Reach Out Today!</h3>
            <p><strong>{title}</strong><br />
            Tel: <a href={`tel:${phone}`}>{phone}</a><br />
            Email: <a href={`mailto:${email}`}>{email}</a></p>
            <button className='btn btn-secondary center-block'><a href='/contact/'>Email Us</a></button>
        </Col> : ''}
    </Col>
)

export default SideBar
