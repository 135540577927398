import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-weight-loss.jpg'

class WeightLossVerona extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query WeightLossVeronaMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Medically Supervised Weight Loss – Verona, NJ | ${data.site.siteMetadata.title}`} description={`Medically supervised weight loss in Verona guides patients to healthy weights by using combinations of effective medication, dietary and nutritional counseling, and exercise programming.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Medically Supervised Weight Loss – Verona, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>While the age-old formula of calories in minus calories out rings true, metabolism and other factors make weight loss an exceedingly complex issue for many individuals. Patients with high levels of body fat are at risk for numerous diseases and health issues, including diabetes, heart failure or heart attacks, hypertension, and strokes. Simply “eating healthy” or frequently going to the gym may not be sufficient to establish lasting and sufficient weight loss for long-term health.</p>

                                    <p>Medically supervised weight loss in Verona guides patients to healthy weights by using combinations of effective medication, dietary and nutritional counseling, and exercise programming. Nutrition counseling programs have been a proven method of weight loss for years, and have helped countless individuals return to healthy body fat and weight levels.</p>

                                    <h2>How does Medically Supervised Weight Loss Work?</h2>

                                    <p>Medically supervised weight loss in Verona is a process that caters to each individual. There is no “one size fits all” method, because every individual, as well as their weight loss journey, is unique.</p>

                                    <h3><em>Consultation and Planning</em></h3>

                                    <p>First, you’ll want to contact your Verona doctor at Optimal Health and Wellness to schedule a consultation. During the consultation, here you will discuss important details such as weight history, medical history, and psychological history. We will try to determine whether or not there are undiagnosed medical conditions such as thyroid issues, liver conditions, sleep issues, etc.</p>

                                    <p>Additional testing could potentially be done after an initial consultation in order to determine if any other issues are evident that could factor into the preparation and execution of the medically supervised weight loss plan.</p>

                                    <h3><em>Meal Replacements</em></h3>

                                    <p>Some programs give a loose plan for meal replacements, such as identifying general healthy foods and meals to eat daily for weight loss. However, others recognize the need for taking out decision-making and partner with meal prep providers. This takes the difficulty of deciding what to eat, or even cooking your meals, out of the equation and makes it a simple process for eating the correct meals.</p>

                                    <p>This will be discussed in the planning and consultation stage and is dependent on each individual and the difficulties that they face. Insurance coverage for weight loss programs could factor in as well, as some insurance plans provide assistance for medically supervised weight loss programs.</p>

                                    <h3><em>Weight Loss Medication</em></h3>

                                    <p>For those that have historically had extreme difficulty losing weight with diet and exercise alone, weight loss medication can be prescribed by your Verona doctor to maximize weight loss capability in a nutrition counseling plan. There are a variety of different medications that can be assigned to a client, and like the plan as a whole, is dependent on the individual. If the doctor deems that a certain medication will be helpful, it will be discussed and agreed upon during the consultation and planning phase.</p>

                                    <h3><em>Follow-Up</em></h3>

                                    <p>Patients generally follow up every 3 to 4 weeks, and will continue to check in at this rate until initial weight loss goals are met. As an example, this could be that the 3 to 4 week checkouts will continue until a patient loses 10% of their body weight. After that initial goal is met, follow-ups can become less frequent. They will not go away completely, as obesity is considered a life-long difficulty that benefits from check-ins periodically.</p>

                                    <h3><em>Results</em></h3>

                                    <p>Like every other step of the process, results vary depending on the unique individual taking part in the Verona medically supervised weight loss. Many patients can experience up to 40-50 pounds in 20-24 weeks. Some may exceed this, while others may come in well under. This makes the follow-up stage vital in order to tweak programs to cater to individuals that may not be responding to an initial plan of action.</p>

                                    <h2>Call Today</h2>

                                    <p>If you’ve been struggling with losing weight, and finding that simply dieting and exercising are not yielding the success that you desire, medically supervised weight loss in Verona might be the solution you’ve been looking for. <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>Call {data.site.siteMetadata.title}</a> and schedule an initial consultation today!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default WeightLossVerona